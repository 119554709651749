export const weddingInvitationStyles = {
  container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      //alignItems: 'center', // Centra horizontalmente
      maxWidth: '1500px', // Ancho máximo de 1200px
      width: '100%', // Asegura que el ancho sea 100%
      textAlign: 'center',
      background: '#F4EBE2',
      margin: 'auto', // Centra verticalmente
  },
};
